<template>
  <div class="wrapper">
    <parallax class="section" :style="headerStyle"></parallax>
    <div class="main main-raised">
      <div :style="styleHeight">
        <l-map
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="height: 100%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
        >
          <l-tile-layer :url="url" :options="{ maxZoom: 19 }" />

          <l-marker
            @click="innerClick(location.id)"
            :key="index"
            v-for="(location, index) in locations"
            :lat-lng="latLong(location.latitude, location.longitude)"
          >
            <l-icon
              :icon-size="dynamicSize"
              :icon-anchor="dynamicAnchor"
              icon-url="https://vue2-leaflet.netlify.app/images/baseball-marker.png"
            >
              <div class="someExtraClass">
                {{ location.label }}
              </div>
            </l-icon>
          </l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, Icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LPolygon,
  LIcon,
  LPolyline
} from "vue2-leaflet";
import axios from "axios";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  bodyClass: "profile-page",
  data() {
    return {
      styleHeight: 'height: ' +  (window.innerHeight - 67 - 70) + 'px; width: 100%',
      basilica: [],
      locations: [],
      zoom: 18,
      maxZoom: 20,
      center: latLng(41.01124442622451, 21.34249775089572),
      url: "https://api.mapbox.com/styles/v1/arsimajroheraclea/ckwwrdaubg3ng15nv742kl3m4/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXJzaW1hanJvaGVyYWNsZWEiLCJhIjoiY2t3d3I4cmFuMDZkMDJvdXJ2YjNidDk2dCJ9.Aa_Yqqddw9zKXp0Cxg7BRA",
      currentZoom: 17,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false
      },
      showMap: true,
      polygon: {
        latlngs: [],
        color: "#ff00ff"
      },
      staticAnchor: [16, 37]
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick(id) {
      this.$router.push({ path: `/object/${id}` });
    },
    latLong(lat, long) {
      return latLng(lat, long);
    }
  },
  mounted: function() {
    axios.get(process.env.VUE_APP_PROD_URL + "api/sites/1/en").then(r => {
      this.polygon.latlngs = r.data.coordinantes.map(function(elem) {
        return [elem.lat, elem.lng];
      });

      this.basilica = r.data;
      this.locations = r.data.locations;
    });
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    },
    img: {
      type: String,
      default: require("@/assets/img/faces/christian.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }
}

.someExtraClass {
  background-color: rgb(228, 153, 15);
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid #f5b810;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: black;
}

.main-raised {
  border-radius: 0px;
  margin: -20px 0px 0px;
}

.section {
  padding: 40px 0;
}

.sharing-area {
  margin-top: 40px;
}
</style>
