<template>
  <div>
    <h4 class="choose-language">Choose your language</h4>
    <br />
    <ul id="list">
      <li
        id="lang"
        v-for="locale in locales"
        :key="locale"
        @click="switchLocale(locale)"
      >
        <img
          :src="languageFlag(locale)"
          :alt="languages[locale]"
          class="languageFlags"
        />
        <span>
          {{ languages[locale] }}
        </span>
        <i v-if="current == locale" class="material-icons checked">
          check_circle
        </i>
        <i v-else class="material-icons"> radio_button_unchecked </i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  components: {},
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
      this.$router.push({ path: `/leaflet` });
    },
    languageFlag(lang) {
      if (lang == "en") {
        lang = "gb";
      }
      return require("@/assets/img/" + lang.toUpperCase() + ".png");
    }
  },
  data() {
    return {
      current: this.$i18n.locale,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      languages: {
        mk: "Mакедонски",
        en: "English",
        sq: "Shqip",
        de: "Deutsche",
        fr: "Français",
        it: "Italiano",
        pl: "Polski"
      }
    };
  }
};
</script>

<style scoped>
ul {
  margin: auto;
  width: 190px;
  padding: 0px;
}
li {
  text-align: left;
  color: black;
  cursor: pointer;
  list-style-type: none;
  padding: 5px;
  border-bottom: 1px solid lightgray;
}

li i {
  float: right;
  font-size: 16px;
  padding: 5px;
  /* padding-right: 0px; */
}

.languageFlags {
  height: 30px;
}
.checked {
  color: #2a7ddc;
}
.choose-language {
  font-size: 22px;
  color: #222222;
}
</style>
