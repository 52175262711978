<template>
  <div class="wrapper">
    <parallax class="section">
      <md-card style="margin-bottom: 0px;margin-top: 67px;">
        <carousel
          :per-page="1"
          loop
          :speed="700"
          autoplay
          :autoplay-timeout="5000"
          :mouse-drag="false"
          navigationEnabled
          navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
          navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide :key="index" v-for="(item, index) in images">
            <img :src="item" :alt="index" />
          </slide>
        </carousel>
      </md-card>
    </parallax>
    <div class="main main-raised">
      <div class="section section-white">
        <div class="container">
          <div class="wrapper">
            <div id="navigation-pills">
              <div class="md-layout">
                <div class="md-layout-item">
                  <div class="review-flex">
                    <h2 id="review">
                      <b> Events</b>
                    </h2>
                    <div class="share-buttons">
                      <md-button
                        class="md-primary md-simple md-just-icon md-round"
                      >
                        <md-icon style="color:silver !important">
                          share
                        </md-icon>
                      </md-button>
                      <md-button
                        class="md-primary md-simple md-just-icon md-round"
                        @click="goToWriteReview()"
                      >
                        <md-icon style="color:silver !important">
                          star
                        </md-icon>
                      </md-button>
                    </div>
                  </div>
                  <div
                    id="left-review"
                    class="events"
                    :key="index"
                    v-for="(event, index) in bms_events"
                  >
                    <div class="flex-author">
                      <p style="margin-bottom:0px" class="RatingAndName">
                        <span class="author">
                          {{ event.title }}
                        </span>
                      </p>
                    </div>
                    <p>{{ event.description }}</p>
                    <p>{{ event.date }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  bodyClass: "profile-page",
  components: {},
  data() {
    return {
      firstname: null,
      rating: 3,
      textarea: null,
      id: this.$route.params.id,
      basilica: [],
      bms_events: [],
      image: "",
      images: []
    };
  },
  methods: {
    submitReview() {
      const review = {
        author: this.firstname,
        basilica_id: 1,
        rating: this.rating,
        description: this.textarea
      };
      axios
        .post(process.env.VUE_APP_PROD_URL + "api/review/1", review)
        .then(response => {
          this.$toast.success("Thank You for the review!", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        });
    },
    basilisicaName() {
      return '"' + this.basilica.name + "'";
    },
    goToWriteReview() {
      this.$router.push("/write");
    }
  },
  mounted: function() {
    axios.get(process.env.VUE_APP_PROD_URL + "api/events").then(r => {
      this.bms_events = r.data;
    });
    axios
      .get(process.env.VUE_APP_PROD_URL + "api/sites/1/" + this.$i18n.locale)
      .then(r => {
        this.basilica = r.data;
        this.basilica.name = r.data.audios[0].name;
        this.basilica.description = r.data.audios[0].description;
        this.image =
          "'" + process.env.VUE_APP_PROD_URL + "storage/" + r.data.image + "'";
        this.audio =
          process.env.VUE_APP_PROD_URL + "storage/" + r.data.audios[0].path;
        this.images = r.data.images.map(function(elem) {
          return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
        });
      });
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  }
};
</script>

<style lang="scss" scoped>
.yellow {
  color: #a78526;
}
.review-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#review {
  font-family: "Trajan Color";
  color: #a78526 !important;
  text-align: left !important;
  font-size: 16px;
  text-transform: uppercase;
}
#left-review {
  text-align: left !important;
  padding: 20px 0px;
}
.flex-author {
  display: flex;
  flex-direction: row;
  padding-right: 0px;
}
.author {
  color: #a78526;
  text-transform: uppercase;
  float: right;
  font-size: 14px;
  padding: 0px 0 0 0px;
}
.section {
  background: #f3f3f3;
  padding: 0px 0 0 0;
}

.share-buttons {
  margin-left: auto;
}

.main-raised {
  margin: 0px;
}

.RatingAndName i {
  font-size: 20px;
}
</style>
