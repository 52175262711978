<template>
  <div class="wrapper">
    <md-card style="margin-top: 67px;">
      <carousel
        :per-page="1"
        loop
        :speed="700"
        autoplay
        :autoplay-timeout="5000"
        :mouse-drag="false"
        navigationEnabled
        navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
        navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
      >
        <slide :key="index" v-for="(item, index) in images" style='text-align:center;'>
          <img :src="item" :alt="index" />
        </slide>
      </carousel>
    </md-card>
    <div class="main main-raised">
      <div class="section section-white">
        <div class="container">
          <div class="wrapper">
            <div id="navigation-pills">
              <div class="md-layout">
                <div class="md-layout-item">
                  <div class="sharing-area text-center">
                    <div class="button">
                      <h3 id="basilica-name">
                        {{ basilica.name }}
                      </h3>
                      <div class="share-buttons">
                        <md-button
                          class="md-primary md-simple md-just-icon md-round"
                          @click="shareBasilica()"
                        >
                          <md-icon
                            style="color:silver !important"
                          >
                            share
                          </md-icon>
                        </md-button>
                        <md-button
                          class="md-primary md-simple md-just-icon md-round "
                          @click="goToWriteReview()"
                        >
                          <md-icon style="color:silver !important">
                            star
                          </md-icon>
                        </md-button>
                      </div>
                    </div>
                    <md-button class="md-share md-white" @click="goToContact()">
                      <i class="fa fa-clock"></i>
                      {{ basilica.opening_hours }}
                    </md-button>
                    <md-button class="md-star md-white" @click="goToMap()">
                      MAP
                      <i class="fa fa-chevron-circle-right"></i>
                    </md-button>
                    <br />
                    <br />
                    <br />
                    <!-- <div
                      id="basilica-description"
                      v-html="basilica.description"
                    ></div> -->
                    <LongTextComponent :description="basilica.description" />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Player, Audio, DefaultUi } from "@vime/vue";
import axios from "axios";
import LongTextComponent from "./components/LongTextSection";
import { Share } from "@capacitor/share";

export default {
  bodyClass: "profile-page",
  components: {
    LongTextComponent
  },
  data() {
    return {
      classicModal: false,
      firstname: null,
      rating: 3,
      audio: "",
      textarea: null,
      id: this.$route.params.id,
      basilica: [],
      image: [],
      images: [],
      currentIndex: 0
    };
  },
  methods: {
    basilisicaName() {
      return '"' + this.basilica.name + "'";
    },
    NavigationNextLabel: function() {
      this.currentIndex += 1;
    },
    locationFullPath() {
      return `${window.location.origin}/#${this.$route.path}`;
    },
    NavigationPrevLabel: function() {
      this.currentIndex -= 1;
    },
    classicModalHide() {
      this.classicModal = false;
    },
    goToLanding() {
      this.$router.push("/");
    },
    goToMap() {
      this.$router.push("/leaflet");
    },
    goToWriteReview() {
      this.$router.push("/write");
    },
    async shareBasilica() {
      await Share.share({
        title: "Share",
        text: "",
        url: "https://heraclea.archaeology.mk/#/basilica/1",
        dialogTitle: "Share with friends"
      });
    },
    goToContact() {
      this.$router.push("/contact");
    }
  },
  mounted: function() {
    axios
      .get(
        process.env.VUE_APP_PROD_URL +
          "api/sites/" +
          this.id +
          "/" +
          this.$i18n.locale
      )
      .then(r => {
        this.basilica = r.data;
        this.basilica.name = r.data.audios[0].name;
        this.basilica.description = r.data.audios[0].description;
        this.image =
          "'" + process.env.VUE_APP_PROD_URL + "storage/" + r.data.image + "'";
        this.audio =
          process.env.VUE_APP_PROD_URL + "storage/" + r.data.audios[0].path;
        this.images = r.data.images.map(function(elem) {
          return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
        });
      });
  },
  computed: {
    currentImg: function() {
      return this.image[Math.abs(this.currentIndex) % this.image.length];
    }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  }
};
</script>

<style lang="scss" scoped>
.yellow {
  color: orange;
}

@media screen and (min-width: 800px) {
    // this css will only be used when the screen size is min 800px
}
.main-raised {
  border-radius: 0px;
  margin: -30px 0px 0px;
}

.section {
  padding: 0px 0;
}

.sharing-area {
  margin-top: 0px;
}

#basilica-description {
  text-align: justify !important;
  text-justify: inter-word !important;
  font-family: "Myriad Pro";
}

#basilica-name {
  text-align: left !important;
  color: #a78526 !important;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Trajan Color";
}

.button {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.md-star {
  float: right;
}

.md-share {
  float: left;
}

.share-buttons {
  margin-left: auto;
}

.md-layout-item {
  padding-bottom: 15px;
}

.sharing-area .md-button {
  margin: 10px 4px 0;
}
</style>
