<template>
  <div>
    <vue-plyr>
      <audio controls>
        <source :src="AudioFile" type="audio/mpeg" />
        <source :src="AudioFile" type="audio/mpeg" />
      </audio>
    </vue-plyr>
  </div>
</template>

<script>
import "vue-plyr/dist/vue-plyr.css";
import VuePlyr from "vue-plyr";

export default {
  components: {
    VuePlyr
  },
  props: {
    AudioFile: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped></style>
