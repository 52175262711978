<template>
  <div class="wrapper">
    <parallax class="section page-header">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-100 text-center">
            <img
              src="@/assets/img/Main_Logo.png"
              height="auto"
              position="absolute"
              width="280px"
              class="text-center"
              display="inline-block!important"
              justify="center"
              padding-top="150px"
              padding-bottom="0"
              margin-bottom="0"
            />
            <div class="text-center languages-div">
              <LocaleSwitcher />
            </div>
          </div>
        </div>
      </div>
    </parallax>
  </div>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
  bodyClass: "landing-page",
  components: {
    LocaleSwitcher
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/bg7.jpg")
    }
  },
  data() {
    return {
      name: null,
      email: null,
      message: null
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}
.languages-div {
  margin-top: 50px;
}

.page-header {
  height: auto;
  background-color: #f3f3f3;
}
</style>
