<template>
  <div class="wrapper">
    <parallax class="section">
      <md-card style="margin-bottom: 0px;margin-top: 67px;">
        <carousel
          :per-page="1"
          loop
          :speed="700"
          autoplay
          :autoplay-timeout="5000"
          :mouse-drag="false"
          navigationEnabled
          navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
          navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide :key="index" v-for="(item, index) in images">
            <img :src="item" :alt="index" />
          </slide>
        </carousel>
      </md-card>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="faq-flex">
            <h2 id="faq">
              CONTACT US
            </h2>
            <div class="share-buttons">
              <md-button class="md-primary md-simple md-just-icon md-round">
                <md-icon style="color:silver !important">
                  share
                </md-icon>
              </md-button>
              <md-button
                class="md-primary md-simple md-just-icon md-round"
                @click="goToWriteReview()"
              >
                <md-icon style="color:silver !important">
                  star
                </md-icon>
              </md-button>
            </div>
          </div>
          <div class="wrapper">
            <div class="description" v-html="description"></div>
            <md-button
              @click="GoToDirections()"
              slot="footer"
              class="md-simple md-success md-lg"
            >
              {{ $t("directions") }}
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  bodyClass: "faqs-page",
  data() {
    return {
      images: [],
      currentIndex: 0,
      description: ""
    };
  },
  mounted: function() {
    axios
      .get(process.env.VUE_APP_PROD_URL + "api/sites/1/" + this.$i18n.locale)
      .then(r => {
        this.basilica = r.data;
        this.basilica.name = r.data.audios[0].name;
        this.basilica.description = r.data.audios[0].description;
        this.image =
          "'" + process.env.VUE_APP_PROD_URL + "storage/" + r.data.image + "'";
        this.audio =
          process.env.VUE_APP_PROD_URL + "storage/" + r.data.audios[0].path;
        this.images = r.data.images.map(function(elem) {
          return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
        });
      });
    axios.get(process.env.VUE_APP_PROD_URL + "api/contact").then(r => {
      this.description = r.data;
    });
  },
  methods: {
    NavigationNextLabel: function() {
      this.currentIndex += 1;
    },
    NavigationPrevLabel: function() {
      this.currentIndex -= 1;
    },
    goToWriteReview() {
      this.$router.push("/write");
    },
    GoToDirections() {
      window.location.href =
        "https://www.google.com/maps/place/Heraklea+Lynkestis+bei+BITOLA/@41.0109789,21.3403291,17z/data=!3m1!4b1!4m5!3m4!1s0x135724dc9718f1f5:0xf70c4a57038c2cf5!8m2!3d41.0109443!4d21.3425372";
    }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    currentImg: function() {
      return this.image[Math.abs(this.currentIndex) % this.image.length];
    }
  }
};
</script>

<style lang="scss" scoped>
.emri {
  text-align: left;
  color: #a78526 !important;
  font-size: 15px;
  text-transform: uppercase;
}
#faq {
  color: #a78526 !important;
  text-align: left !important;
  font-size: 16px;
  font-family: "Trajan Color";
}
.description {
  color: #222222;
  text-align: left !important;
}
.section {
  background: #f3f3f3 !important;
}
#share {
  text-align: right !important;
}

.main-raised {
  border-radius: 0px;
  margin: -20px 0px 0px;
}

.section {
  padding: 0px 0 0 0;
}

.sharing-area {
  margin-top: 40px;
}
.faq-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.share {
  background-color: #f3f3f3;
}
.star {
  background-color: #f3f3f3;
}

.share-buttons {
  margin-left: auto;
}
</style>
