import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "leaflet/dist/leaflet.css";
import "@vime/core/themes/default.css";
import "@vime/core/themes/light.css";
import "leaflet/dist/leaflet";
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";
import MaterialKit from "./plugins/material-kit";
import VueSocialSharing from "vue-social-sharing";
import i18n from "./i18n";
import { App as app } from "@capacitor/app";

Vue.config.productionTip = false;

Vue.use(VueSocialSharing);
Vue.use(MaterialKit);

const options = {
  // You can set your default options here
};


Vue.use(Toast, options);

const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

new Vue({
  router,
  i18n,
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  created() {
    //  list of pages back button exists the app
    const existPages = ["landing"];
    app.addListener("backButton", state => {
      if (existPages.includes(this.currentRouteName)) {
        app.exitApp();
      } else {
        window.history.back();
      }
    });
  },

  render: h => h(App)
}).$mount("#app");
