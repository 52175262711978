<template>
  <div class="wrapper">
    <parallax class="section">
      <md-card style="margin-bottom: 0px;margin-top: 67px;">
        <carousel
          :per-page="1"
          loop
          :speed="700"
          autoplay
          :autoplay-timeout="5000"
          :mouse-drag="false"
          navigationEnabled
          navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
          navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide :key="index" v-for="(item, index) in images">
            <img :src="item" :alt="index" />
          </slide>
        </carousel>
      </md-card>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="wrapper">
            <h3 class="disclaimer">Disclaimer</h3>
            <p>
              "This application was produced with the financial support of the
              European Union. Its content is the sole responsibility of the
              project partners and does not necessarily reflect the views of the
              European Union."
            </p>
            <img
              src="@/assets/img/smart_cul_tour_LOGO.png"
              alt=""
              srcset=""
              width="40%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  bodyClass: "faqs-page",
  data() {
    return {
      faqs: [],
      images: [],
      currentIndex: 0
    };
  },
  mounted: function() {
    axios
      .get(process.env.VUE_APP_PROD_URL + "api/sites/1/" + this.$i18n.locale)
      .then(r => {
        this.basilica = r.data;
        this.basilica.name = r.data.audios[0].name;
        this.basilica.description = r.data.audios[0].description;
        this.image =
          "'" + process.env.VUE_APP_PROD_URL + "storage/" + r.data.image + "'";
        this.audio =
          process.env.VUE_APP_PROD_URL + "storage/" + r.data.audios[0].path;
        this.images = r.data.images.map(function(elem) {
          return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
        });
      });
  },
  methods: {
    NavigationNextLabel: function() {
      this.currentIndex += 1;
    },
    NavigationPrevLabel: function() {
      this.currentIndex -= 1;
    }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    currentImg: function() {
      return this.image[Math.abs(this.currentIndex) % this.image.length];
    }
  }
};
</script>
<style scoped>
.disclaimer {
  text-align: left !important;
  color: #a78526 !important;
  font-size: 16px;
  font-family: "Trajan Color";
  text-transform: uppercase;
}
p {
  text-align: left !important;
}

.section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.main-raised {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
