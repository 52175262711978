<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <div class="desc" v-html="displayDescription()"></div>
      <div v-show="isLongDescription">
        <p
          v-if="showMore"
          class="md-star md-white"
          @click="toggleShowButtons()"
        >
          {{ $t("read_more") }}
        </p>
        <p v-else class="md-star md-white" @click="toggleShowButtons()">
          {{ $t("read_less") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { convert } from "html-to-text";

export default {
  data() {
    return {
      isLongDescription: true,
      showMore: true
    };
  },
  props: {
    description: {
      type: String,
      default: ""
    }
  },
  methods: {
    displayDescription() {
      const text = convert(this.description, {
        wordwrap: 130
      });
      return this.showMore ? `${text.substring(0, 300)}...` : this.description;
    },
    toggleShowButtons() {
      this.showMore = !this.showMore;
    }
  }
};
</script>

<style lang="scss" scoped>
.desc {
  font-family: "Myriad Pro";
  text-align: justify !important;
  text-justify: inter-word !important;
}
.md-star {
  // float: right;
}
</style>
