<template>
  <div class="wrapper">
    <parallax class="section">
      <md-card style="margin-bottom: 0px;margin-top: 67px;">
        <carousel
          :per-page="1"
          loop
          :speed="700"
          autoplay
          :autoplay-timeout="5000"
          :mouse-drag="false"
          navigationEnabled
          navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
          navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide :key="index" v-for="(item, index) in images">
            <img :src="item" :alt="index" />
          </slide>
        </carousel>
      </md-card>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="wrapper">
            <h3 class="disclaimer">Privacy Policy</h3>
            <p>
              The purpose of this document is to inform Users with regard to 
              Personal Data collected from the application HERACLEA AUDIO GUIDE, 
              NI INSTITUTE AND MUSEUM BITOLA (hereinafter, the Application). 
              The Data Controller, as identified below, may amend or simply update, 
              wholly or in part, this Privacy Policy informing the Users about the changes. 
              The amendments or updates shall be binding as soon as they are published on the Application. 
              Therefore, the User is invited to read the Privacy Policy at every access to the Application. 
              If the User does not accept any amendments to the Privacy Policy, the User must terminate the
               use of the Application and he may ask the Data Controller to remove his Personal Data.
            </p>
            <h3 class="disclaimer">1. Personal Data collected by the Application</h3>
            <p>
              The Data Controller shall collect the following categories of Personal Data:
A. Voluntary contents and information provided by the User Contact information and contents: for example biographical data, e-mail address or postal address or other contact details, security  information used for authentication and account access, personal interests and preferences and any other personal contents, etc.
Personal Data from Social Media: the Users may share Data they have communicated to social media with the Application. The User can monitor Personal Data which the Application may have access to when granting access to his social media accounts and through the privacy settings of the relevant social media website. By way of connecting accounts managed by social media to theApplication and by way of authorizing his Personal Data access, the User gives his consent to the acquisition, processing and retention of Data provided by the same social media, in accordance with this Privacy Policy.
If the User does not communicate Personal Data, for which there is a legal or contractual obligation, will be impossible to the DataController to provide, in whole or in part, its services. It will be impossible also in case that Personal Data is necessary requirement for the use of the service or for the contract conclusion.
The User who communicates to the Data Controller third parties Personal Data, is directly and exclusively liable for their origin,collection, processing, communication or disclosure.
B. Data and contents automatically acquired while using the Application
Technical Data: the computer system and the software procedures functional to this Application may acquire, in the course oftheir ordinary activity, any Personal Data whose communication is implicit in the use of internet communication protocols. Suchinformation is not collected to be associated with identified Users, however, those Data, due to its nature, may identify Users in theProcessing and through the association with Data held by third parties.
This category includes IP addresses or domain names usedby Users who connect to the Application, addresses of Uniform Resource Identifier (URI) of the requested resources, time of therequest, method used submitting the request to the server, size of the file obtained, etc.
Usage Data: Data may be collected relating to the use of the Application by the User, such as the pages visited, the actionsperformed, the features and services used by the User.
Geolocation Data: the Application may collect location Data, which may be accurate or inaccurate. The precise location Data mayconsist in GNSS Data (Global Navigation Satellite System, such as GPS), as well as in Data which identify the nearest repeater, Wi-Fi hotspots and bluetooth, communicated when you enable products or features based on the location.
C. Personal Data collected through cookies or similar technologies
Personal Data of the Users are not acquired by the Application using these technologies.
Cookies, or any type of persistent cookies and systems for tracking the Users, are not used for the communication of personalinformation. The use of session cookies (which are not permanently stored on the computer of the User and disappear with thetermination of the browser) is strictly limited to the transmission of session identification Data (composed by random numbersgenerated by the server) necessary to allow a safe and efficient exploration of the Application. Session cookies used in this Application prevent to use other technologies, which may compromise the privacy of Users while browsing on internet and do not allow the acquisition of Personal Data identifying the User.

            </p>
             <h3 class="disclaimer">2. Purposes of the processing</h3>
             <p>
                Collected Personal Data may be used for the performance of contractual and pre-contractual obligations, 
                legal obligations and for the following purposes:to sign as “ visited “ the points of interests where user has been 
                and listened to the audio/video content.
             </p>
            <h3 class="disclaimer">3. Personal Data processing methods</h3>
            <p>
              The Processing of Personal Data is performed with paper, IT and/or digital tools, with methods of organizations and with logics strictly related to the indicated purposes.
In certain cases, subjects other than the Data Controller who are involved in the organization of the Data Controller (such as personnel management, sales personnel, system administrators employees, etc.) or who are not (as IT companies, service providers, postal couriers, hosting providers, etc.) may access to Personal Data. These subjects, will be appointed, where necessary, as Data Processors by the Data Controller and will have access to Users Personal Data whenever required, and shall be contractually obliged to keep it confidential.
The updated list of Data Processors may be requested via email at the email address info@muzejbitola.mk.

            </p>
            <h3 class="disclaimer">4. Legal basis for the processing</h3>
            <p>
              The Data Controller processes Personal Data regarding the User whether one of the following applies: the User has given consent for one or more specific purposes;
Processing is necessary for the performance of a contract with the User and/or for the performance of pre-contractual measures;
Processing is necessary to comply with a legal obligation to which the Data Controller is subject;
Processing is necessary for the performance of a task carried out in the public interest or for the exercise of public authority vested in the Data Controller;
Processing is necessary for the purposes of the legitimate interest pursued by the Data Controller or by a third party.It is always possible to ask the Data Controller to clarify the concrete legal basis of each processing.

            </p>
            <h3 class="disclaimer">5. Place</h3>
            <p>
              Personal Data are processed in the operative offices of the Data Controller and in any other place in which the parties involved in the Dataprocessing are located. For further information, you may contact the Data Controller at the following email address info@muzejbitola.mk.
            </p>
            <h3 class="disclaimer">6. Security of processing</h3>
            <p>
              The Data Processing is performed through adequate methods and tools to ensure the Personal Data security and confidentiality, as the Data Controller has implemented appropriate technical and organizational measures which guarantee, in a provable way, that the Processing complies with the applicable law.
            </p>
            <h3 class="disclaimer">7. Period of storage of Data</h3>
            <p>
              The Data Controller will store Personal Data for the necessary time to perform the purposes connected with the performance of theagreement in force between the Data Controller and the User and Data shall not be stored beyond the duration of years 1 after the end of the relationship with the User. In any case Data could be stored within the limitation term prescribed by the current regulations.
When the Processing of Personal Data is necessary for the purposes of the legitimate interests pursued by the Data Controller, Personal Data shall be stored until the performance of the relevant interest.
When the Processing of Personal Data is based on the User consent, the Data Controller may store Personal Data until the withdrawal of the User.
Personal Data may be stored for a longer period of time in order to perform a legal obligation or public Authority order.
All Personal Data shall be deleted at the end of the period of storage. At the expiration of such period, the rights of Data access,rectification, erasure and portability must not be exercised.

            </p>
            <h3 class="disclaimer">8. Rights of the User</h3>

          <p>
            The Users may exercise specifc rights with respect to Personal Data processed by the Data Controller. In particular, the User has the right to:
withdraw its consent at any time;
object the Processing of its Data;
access its Data;
monitor and request the rectification of Data;
obtain a restriction of Processing;
obtain the erasure or remotion of its Personal Data;
receive its Data or obtain the transfer to a different Data Controller;
lodge a complaint before the supervisory authority for the protection of personal data or start legal proceedings.
In order to exercise their rights, the Users may send a request to the contact information of the Data Controller indicated in thisdocument. These requests are free of charge and performed by the Data Controller in the shortest possible time, in any case no later than 30 days.

          </p>

            <img
              src="@/assets/img/smart_cul_tour_LOGO.png"
              alt=""
              srcset=""
              width="40%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  bodyClass: "faqs-page",
  data() {
    return {
      faqs: [],
      images: [],
      currentIndex: 0
    };
  },
  mounted: function() {
    axios
      .get(process.env.VUE_APP_PROD_URL + "api/sites/1/" + this.$i18n.locale)
      .then(r => {
        this.basilica = r.data;
        this.basilica.name = r.data.audios[0].name;
        this.basilica.description = r.data.audios[0].description;
        this.image =
          "'" + process.env.VUE_APP_PROD_URL + "storage/" + r.data.image + "'";
        this.audio =
          process.env.VUE_APP_PROD_URL + "storage/" + r.data.audios[0].path;
        this.images = r.data.images.map(function(elem) {
          return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
        });
      });
  },
  methods: {
    NavigationNextLabel: function() {
      this.currentIndex += 1;
    },
    NavigationPrevLabel: function() {
      this.currentIndex -= 1;
    }
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`
      };
    },
    currentImg: function() {
      return this.image[Math.abs(this.currentIndex) % this.image.length];
    }
  }
};
</script>
<style scoped>
.disclaimer {
  text-align: left !important;
  color: #a78526 !important;
  font-size: 16px;
  font-family: "Trajan Color";
  text-transform: uppercase;
}
p {
  text-align: left !important;
}

.section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.main-raised {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
