<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div>
        <img src="@/assets/img/Europian-cross-border---eng.png" class="footerImages" />
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.container{
  text-align: center;
  justify-content: center!important;
}
.footer {
  padding: 5px;
}

.footerImages {
  height: 60px !important;
}
</style>
