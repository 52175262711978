<template>
  <div class="wrapper">
    <md-card style="margin-top: 67px;">
      <carousel
        :per-page="1"
        loop
        :speed="700"
        autoplay
        :autoplay-timeout="5000"
        :mouse-drag="false"
        navigationEnabled
        navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
        navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
      >
        <slide :key="index" v-for="(item, index) in images">
          <img :src="item" :alt="index" />
        </slide>
      </carousel>
    </md-card>
    <div class="main main-raised">
      <div class="section section-white">
        <div class="container">
          <div class="wrapper">
            <div id="navigation-pills">
              <div class="title">
                <div class="someExtraClass">
                  {{ location.label }}
                </div>
                <h3 class="o-title">{{ location.name }}</h3>
                <div class="share-buttons">
                  <md-button
                    class="md-primary md-simple md-just-icon md-round"
                    @click="shareLocation()"
                  >
                    <md-icon style="color:silver !important">
                      share
                    </md-icon>
                  </md-button>
                  <md-button
                    class="md-primary md-simple md-just-icon md-round"
                    @click="goToWriteReview()"
                  >
                    <md-icon style="color:silver !important">
                      star
                    </md-icon>
                  </md-button>
                </div>
              </div>
              <AudioPlayer
                v-if="renderComponent"
                v-bind:AudioFile="AudioFile"
              />
              <br />
              <!-- <div class="md-layout">
                <div class="md-layout-item md-small-size-100">
                  <div id="desc" v-html="location.description"></div>
                </div>
              </div> -->
              <LongTextComponent :description="location.description" />
              <md-button
                v-if="location.back"
                class="md-white"
                @click="gotoPrevious()"
              >
                <i class="fa fa-chevron-circle-left"></i>
                BACK
              </md-button>
              <md-button class="md-white" @click="goToMap()">
                <i class="material-icons">map</i>
                MAP
              </md-button>

              <md-button
                v-if="location.next"
                class=" md-white"
                @click="goToNext()"
              >
                NEXT
                <i class="fa fa-chevron-circle-right"></i>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Player, Audio, DefaultUi } from "@vime/vue";
import { Share } from "@capacitor/share";
import LongTextComponent from "./components/LongTextSection";
import AudioPlayer from "./components/AudioPlayer";

export default {
  bodyClass: "profile-page",
  components: {
    LongTextComponent,
    AudioPlayer
  },
  data() {
    return {
      id: this.$route.params.id,
      location: [],
      image: [],
      images: [],
      audio: "",
      isLongDescription: true,
      showMore: true,
      renderComponent: false,
      AudioFile: ""
    };
  },
  computed: {
    locationFullPath() {
      return `${window.location.origin}/#${this.$route.path}`;
    }
  },
  mounted: function() {
    this.makeAPiCall(this.id);
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  },
  methods: {
    goToMap() {
      this.$router.push("/leaflet");
    },
    goToWriteReview() {
      this.$router.push("/write");
    },
    displayDescription() {
      const text = convert(this.location.description, {
        wordwrap: 130
      });
      return this.showMore ? `${text.substring(0, 300)}...` : text;
    },
    toggleShowButtons() {
      this.showMore = !this.showMore;
    },
    renderOff() {
      this.renderComponent = false;
    },
    renderOn() {
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async goToNext() {
      await this.makeAPiCall(this.location.next);
    },
    async gotoPrevious() {
      await this.makeAPiCall(this.location.back);
    },
    async shareLocation() {
      await Share.share({
        title: "Share",
        text: "",
        url: "https://heraclea.archaeology.mk/#/object/" + this.id,
        dialogTitle: "Share with friends"
      });
    },
    async makeAPiCall(id) {
      this.renderOff();
      await axios
        .get(
          process.env.VUE_APP_PROD_URL +
            "api/object/" +
            id +
            "/" +
            this.$i18n.locale
        )
        .then(r => {
          this.location = r.data[0];
          this.location.name = r.data[0].audios[0].name;
          this.location.description = r.data[0].audios[0].description;
          this.isLongDescription =
            r.data[0].audios[0].description.length > 600 ? true : false;
          this.image =
            process.env.VUE_APP_PROD_URL + "storage/" + r.data[0].image_path;
          this.audio = this.AudioFile =
            process.env.VUE_APP_PROD_URL +
            "storage/" +
            r.data[0].audios[0].path;
          this.images = r.data[0].images.map(function(elem) {
            return process.env.VUE_APP_PROD_URL + "storage/" + elem.path;
          });
          this.location.next = r.data.next;
          this.location.back = r.data.back;
          this.AudioFile = this.audio;
        });
      if (this.id != id) {
        this.$router.push("/object/" + id);
      }
      this.renderComponent = true;
      this.renderOn();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-raised {
  border-radius: 0px;
  margin: -30px 0px 0px;
}

.section {
  padding: 00px 0;
}

.sharing-area {
  margin-top: 40px;
}
// #desc {
//   font-family: "Myriad Pro";
//   text-align: justify !important;
//   text-justify: inter-word !important;
// }
.title {
  text-align: left;
  color: #a78526 !important;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.o-title {
  float: right;
  font-family: "Trajan Color";
  font-size: 16px;
  text-transform: uppercase;
}
.someExtraClass {
  margin-top: 20px;
  margin-right: 5px;
  background-color: #a78526;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 1px solid #a78526;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: black;
}
.md-theme-default {
  background-color: #f3f3f3;
}
.md-star {
  float: right;
}
.md-share {
  float: left;
}
.fas .fa-arrow-right .fa .fa-arrow-left {
  color: #a78526 !important;
}

.share-buttons {
  margin-left: auto;
}

.container {
  padding-bottom: 15px;
}
</style>
